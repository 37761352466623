import React,{ useState , useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useSelector , useDispatch } from 'react-redux';
import axios from "axios";
import { modulePlatforms, environmentPlatforms, threeDAssetType } from '../../utils/constants';

const locationDataType = ["MODULE", "ENVIRONMENT", "3DASSET"];

const navigateRoute = {
    "MODULE": "/dashboard/xr/deploy", 
    "ENVIRONMENT": "/dashboard/environments/deploy", 
    "3DASSET": "/dashboard/3d/deploy"
}

const ChannelsSelect = () =>{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();  
    if(!location.state.name || !location.state.type || !location.state.channel ){
      navigate("/dashboard/xr")
    }
    const locationData = location.state;
    const params = useParams();
    const moduleUUID = params?.uuid;
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [showFilterContent, setShowFilterContent] = useState("");
    const [moduleName, setModuleName] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedVersions, setSelectedVersions] = useState({});
    const [selectedCourses, setSelectedCourses] = useState({});
    const [selectedChannel,setSelectedChannel] = useState([]);
    const [sectionList,setSectionList] = useState([]);
    const [allVersionGroup , setAllVersionGroup] = useState({})
    const [showVersions, setShowVersions] = useState(false);
    const [showCourses, setShowCourses] = useState(false);
    const [operations , setOperations] = useState({
            "basicDetails" : false,
            "sop" : false,
            "sop2D" : false,
            "tasks" : false,
            "video" : false,
            "quiz" : false,
            "dynamicContent": false
    });

    let platformList = {
        "MODULE": modulePlatforms,
        "ENVIRONMENT": environmentPlatforms,
        "3DASSET": threeDAssetType,
    }

    useEffect(()=>{
       setSelectedChannel([...locationData.channel]);
       setModuleName(locationData.name);
    },[locationData])

    const openFilterContent = (filter) => {
        setShowFilterContent(filter);
    };

    const getAllVersionList = async() =>{
          try {
              let {data} = await axios.get(`${configData.config.UNO_URL}/api/resource/asset/all/versions/${location.state.type}/${moduleUUID}`, {
                  headers: {
                      Authorization: `Bearer ${userData?.token}`,
                      "Content-Type ": "application/json"
                  }
              });
                const groupedData = {};
                let _versions = data?.data;
                let _allVersions = _versions?.map((e,t)=>{
                    return {
                        ...e.data,
                        version: e.version,
                        versionId: e.uuid,
                    };
                });
                
                // Group By platform
                _allVersions.forEach(item => {
                    const platform = item.platform;
                    if (!groupedData[platform]) {
                        groupedData[platform] = [];
                    }
                    groupedData[platform].push(item);
                });
                
            setAllVersionGroup({...groupedData})
            
          } catch (error) {
              console.log(error);
          }


    }

    const getAllSectionsList = async()=>{
        try {
            
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/section/list/${moduleUUID}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${userData.token}`
                }
              });

            setSectionList(data.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
      if(moduleUUID?.length > 0){
          if(locationDataType.includes(locationData.type)){
              getAllVersionList();
            }else if(locationData.type === "2DLMS"){
              getAllSectionsList();

          }
      }

    }, [moduleUUID])


    const handleVersionSelect = (platform, e) => {
        setSelectedVersions((prevSelectedVersions) => ({
          ...prevSelectedVersions,
          [platform]: e,
        }));
      };
    const handleCourseSelect = (name, uuid) => {
        setSelectedCourses((prevCheckedCourses) => ({
            ...prevCheckedCourses,
            [uuid]: !prevCheckedCourses[uuid],
          }));
      };


    const selectOption = (id) => {
            const updatedOperations = { ...operations };
            updatedOperations[id] = !updatedOperations[id];
            setOperations(updatedOperations);
    }

    const checkValidation = async() =>{
        const hasSelectedVersions = Object.keys(selectedVersions).length > 0;
        const hasValidOperation = Object.values(operations).some(value => value === true);
        return hasSelectedVersions || hasValidOperation;      
    }

    const handleAssetsOptionClick = () => {
        let showVersionValue = showVersions;
        setShowVersions(!showVersionValue)
        if(showVersionValue){
            setSelectedVersions({});
        }
    }

    const handleCoursesOptionClick = () => {
        let showVersionValue = showCourses;
        setShowCourses(!showVersionValue)
        if(showVersionValue){
            setSelectedVersions({});
        }
    }

    const submitSynchronizationData = async() =>{
        try {
            if(!await checkValidation()){
                setError("Kindly select at least one check box.");
                setTimeout(()=>{ setError(null) }, 2500);
                return
            }
                setLoading(true);

                let platformVersionArray = []

                if(locationDataType.includes(locationData.type)){
                    platformVersionArray = Object.values(selectedVersions).map(item => {
                        return { platform: item.platform, version: item.version };
                    });
                }

                // else if(locationData.type === "2DLMS"){
                //     platformVersionArray = Object.keys(selectedCourses)
                // }

                for (let key in operations) {
                if (operations.hasOwnProperty(key) && (operations[key] === false || (key === "assets" && operations[key].length === 0))) {
                        delete operations[key];
                    }
                }

                let _bodyData = {
                    "type" : locationData.type,
                    "directoryIds" : selectedChannel.map((e)=> e.id),
                    "operations" : {
                            ...operations,
                            assets : platformVersionArray
                            }
                }

            let { data } = await axios.post(`${configData.config.UNO_URL}/api/sync/resource/${moduleUUID}`, _bodyData , {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "application/json"
            }});

            let operationId = data.data.operationId;

            setLoading(false);
            navigate(`${navigateRoute[locationData.type]}/${moduleUUID}/${operationId}`);

        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }

    }

    // const handleCancel = () =>{
    //     handleReset();
    //     navigate(`/dashboard/xr`);
    // }

    // const handleReset = () =>{
    //     setSearchQuery("");
    //     setShowVersions(false);
    //     setSelectedVersions({});
    //     setOperations({
    //         "basicDetails" : false,
    //         "sop" : false,
    //         "sop2D" : false,
    //         "tasks" : false,
    //         "video" : false,
    //         "quiz" : false,
    //         "dynamicContent": false
    //     })
    // }

    return(
        <>
        <div className='container-fluid'>

            <div className='channelspage '>
            <div className="row align-items-center">
            <div className="col-12">
                <div className="headdetail pt-2 mb-1">
                        <p className="backcrum" onClick={() => navigate(-1)}>
                        <i className="bi-chevron-left"></i> Back
                        </p>
                        <span className="pagetitle" >
                        {moduleName}
                        </span>
                    </div>
                    </div>
                    </div>
            </div>
            <div className='mainbox '>
                <div className='row'>
                    <div className='col-12'>
                        <p className='pagetitle'>
                        </p>
                        <div className='channeltag mt-2'>
                            {selectedChannel?.map((e,i)=>{
                                    return  <p key={i} > <i className="bi-hdd-stack"></i> {e.name} </p>
                            })}
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-md-6'>
                        <div className='Selectionlist'>

                            <div className='selecttype' onClick={()=>selectOption('basicDetails')} id='basicDetails'>
                                    <p>Basic Details {locationData.type === "2DLMS" && "& Content"}  </p>
                                    <div className="form-check listchild">
                                            <input className="form-check-input" name="basicDetails" type="checkbox" checked={operations.basicDetails}  id="defaultCheck1"  />
                                            <label className="form-check-label" htmlFor="defaultCheck1">
                                            </label>
                                        </div>
                            </div>
                            

                           {locationData.type === "MODULE" && 
                            <>
                                <div className='selecttype' onClick={()=>selectOption('tasks')} id='tasks'>
                                    <p>Task List & Passing Criteria</p>
                                    <div className="form-check listchild">
                                            <input className="form-check-input" name="tasks" type="checkbox" checked={operations.tasks} id="defaultCheck2"  />
                                            <label className="form-check-label" htmlFor="defaultCheck2">
                                            </label>
                                        </div>
                                </div>

                                <div className='selecttype' onClick={()=>selectOption('quiz')} id='quiz'>
                                    <p>Quiz</p>
                                    <div className="form-check listchild">
                                            <input className="form-check-input" name="quiz" type="checkbox" checked={operations.quiz} id="defaultCheck3"  />
                                            <label className="form-check-label" htmlFor="defaultCheck3">
                                            </label>
                                        </div>
                                </div>

                                <div className='selecttype' onClick={()=>selectOption('video')} id='video'>
                                    <p>Video</p>
                                    <div className="form-check listchild">
                                            <input className="form-check-input" name="video" type="checkbox" checked={operations.video} id="defaultCheck7"  />
                                            <label className="form-check-label" htmlFor="defaultCheck7">
                                            </label>
                                        </div>
                                </div>
                            </>
                            }

                            {locationData.type === "MODULE"  &&
                                <div className='selecttype' onClick={()=>selectOption("sop")} id='sop'>
                                <p>SOP</p>
                                <div className="form-check listchild">
                                        <input className="form-check-input" name="sop" type="checkbox" checked={operations.sop} id="defaultCheck4"  />
                                        <label className="form-check-label" htmlFor="defaultCheck4">
                                        </label>
                                </div>
                                </div>
                            }
                            
                            {locationData.type === "2DLMS" &&
                                <div className='selecttype' onClick={()=>selectOption("sop2D")} id='sop2D'>
                                <p>SOP</p>
                                <div className="form-check listchild">
                                        <input className="form-check-input" name="sop2D" type="checkbox" checked={operations.sop2D} id="defaultCheck10"  />
                                        <label className="form-check-label" htmlFor="defaultCheck10">
                                        </label>
                                </div>
                                </div>
                            }

                           {locationData.type === "ENVIRONMENT" && 
                            <>
                                <div className='selecttype' onClick={()=>selectOption("dynamicContent")} id='dynamicContent'>
                                    <p>Dynamic Content</p>
                                    <div className="form-check listchild">
                                            <input className="form-check-input" name="dynamicContent" type="checkbox" checked={operations.dynamicContent} id="defaultCheck8"  />
                                            <label className="form-check-label" htmlFor="defaultCheck8">
                                            </label>
                                        </div>
                                </div>
                            </>
                            }

                            {locationDataType.includes(locationData.type) &&
                                <div className='selecttype' onClick={handleAssetsOptionClick}>
                                    <p>Assets</p>
                                    <div className="form-check listchild"  >
                                            <input className="form-check-input" name="assets" type="checkbox" checked={showVersions} id="defaultCheck6"  />
                                            <label className="form-check-label" htmlFor="defaultCheck6">
                                            </label>
                                        </div>
                                </div>
                            }

                            {/* { locationData.type === "2DLMS" &&
                                <div className='selecttype' onClick={handleCoursesOptionClick}>
                                    <p>Courses</p>
                                    <div className="form-check listchild"  >
                                            <input className="form-check-input" name="assets" type="checkbox" checked={showCourses} id="defaultCheck9"  />
                                            <label className="form-check-label" htmlFor="defaultCheck9">
                                            </label>
                                        </div>
                                </div>
                            } */}
                        
                        </div>
                    </div>

                    <div className='col-md-6'>
                        {showVersions &&
                            <>
                                <div className='versionfilterbox'>
                                <div className='versiontitle'> Select Version </div>
                                <div className='VersionSelect'>
                                {Object.keys(allVersionGroup).map((key, index) => (
                                            <div className={ showFilterContent === `${key}` ? 'filterone active' : 'filterone'} key={index+1} >

                                                <div className='filterhead' onClick={() => showFilterContent === `${key}` ? openFilterContent("") : openFilterContent(`${key}`) }>
                                                    <div>
                                                        {platformList[locationData.type].filter((e)=>e.key == key)[0].name}
                                                        {selectedVersions[key]?.version &&  <p className='selectedv'> {"Version "+selectedVersions[key]?.version} </p> }
                                                    </div>
                                                    { showFilterContent === `${key}` ? <RemoveOutlinedIcon/> : <AddOutlinedIcon/> }
                                                </div>

                                                <div className='filterchilds'>
                                                    <label className='filterseach'>
                                                        <i className="bi-search"/>
                                                        <input className='inputsearch' placeholder='Search Version'
                                                            value={searchQuery}
                                                            onChange={(e)=>setSearchQuery(e.target.value)}
                                                            />
                                                    </label>

                                                    <div className='select_list'>
                                                        {allVersionGroup[key].filter((e) => e.version.includes(searchQuery)).map((e, index) => (
                                                            <div className="form-check listchild" key={index}>
                                                                <input className="form-check-input"  type="radio" value={e.version}  id={`radio_${key}_${e.version}`}
                                                                        checked={selectedVersions[key]?.version === e.version}
                                                                        onChange={() => handleVersionSelect(key, e)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`radio_${key}_${e.version}`}>
                                                                Version {e.version}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>

                                            </div>
                                    ))}
                                </div>
                                </div>
                            </>
                        }

                        {/* {showCourses &&
                            <>
                                <div className='versionfilterbox'>
                                <div className='versiontitle'> Select Courses </div>
                                <div className='VersionSelect'>
                                    {sectionList.length &&
                                        sectionList.map((item,index) =>(
                                            <div className={ showFilterContent === `${item.uuid}` ? 'filterone active' : 'filterone'} key={item.uuid} >
                                                <div>
                                                    <div className='filterhead' onClick={() => showFilterContent === `${item.uuid}` ? openFilterContent("") : openFilterContent(`${item.uuid}`) }>
                                                        <div> {item.name} </div> 
                                                        { showFilterContent === `${item.uuid}` ? <RemoveOutlinedIcon/> : <AddOutlinedIcon/> } 
                                                    </div>
                                                </div>

                                                <div className='filterchilds'>
                                                    <label className='filterseach'>
                                                        <i className="bi-search"/>
                                                        <input className='inputsearch' placeholder='Search'
                                                            value={searchQuery}
                                                            onChange={(e)=>setSearchQuery(e.target.value)}
                                                            />
                                                    </label>
                                                    <div className='select_list'>
                                                       {item.children.map((e,i)=>{
                                                         if(!e.name.toLowerCase().includes(searchQuery.toLowerCase())) return null;
                                                        return(
                                                          <div className="form-check listchild" key={e.uuid}>
                                                            <input className="form-check-input" type="checkbox" value={e.uuid}  id={`checkbox_${e.uuid}`} 
                                                                    checked={selectedCourses[e.uuid] || false}
                                                                    onChange={() => handleCourseSelect(e.name , e.uuid)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`checkbox_${e.uuid}`}>
                                                            {e.name}
                                                            </label>
                                                           </div>
                                                      )}
                                                       )}
                                                    </div>
                                                </div>

                                            </div>
                                          )
                                        )
                                       }
                                </div>
                                </div>
                            </>
                        } */}
                        
                    </div>
                    </div>

                </div>
                <div className="col-12">
                <div className="footerbtn text-end"> 
                   {error && <p className='errorMessage' >{error}</p>}
                </div>
                    
                <div className="footerbtn text-end">
                    {/* <button type="button" className="btn btn-secondary" onClick={handleReset}>reset</button> */}
                    {/* <button type="button" className="btn btn-secondary" onClick={handleCancel}>Close</button> */}
                    
                    {loading ?
                    <button className="btn btn-primary" > processing....</button>
                    :
                    <button onClick={ submitSynchronizationData }  type="button" className="btn btn-primary" >Synchronize</button>
                    }
                </div>
                </div>

        </div>

      </>
    )
}
export default ChannelsSelect
