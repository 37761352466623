export const XR_MODULE_LIST = "/api/resource/module/list";
export const XR_MODULE_CREATE = "/api/resource/module/create";
export const XR_MODULE_DETAIL = "/api/resource/module/basic";
export const XR_MODULE_VIDEO = "/api/resource/module/video";
export const XR_ASSET_VERSION_LIST = "/api/resource/asset/list/versions";
export const XR_ASSET_VERSION_CREATE = "/api/resource/asset/create/version";
export const XR_MODULE_TASK_SCENARIO = "/api/resource/module/tasks";
export const TAG_LIST = "/api/tag/list";
export const CREATE_TAG = "/api/tag/create";
export const PUBLISH_CATEGORY = "/api/sync/category";
export const PUBLISH_OPERATION_STATUS = "/api/sync/operation/status";
export const CHANNEL_LIST = "/api/channel/list";
export const LINKED_ASSET_LIST = "/api/resource/linked/list";
export const CONNECT_ASSETS = "/api/resource/linked";
export const CATEGORY_ASSETS_LIST = "/api/resource/3d/asset/list";
export const CATEGORY_CATEGORIES_LIST = "/api/tag/category/list/resources";