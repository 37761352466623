import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import { FileUploader } from 'react-drag-drop-files';
import { DummyVersions } from './DummyVersion';
import { BreadRightArrow, CancelIcon, CloseIcon, DownloadIcon, FolderIcon, LeftArrow, SortIcon, UpDownIcon } from '../../Static/icons/iconshere';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import axios from 'axios';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { assetsTag3DResourceLists, createNewAssetTags } from '../utils';
import { Download } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { convertFileSize, threeDAssetType } from '../../utils/constants';
import moment from 'moment';
import { BtnLoading } from '../../animations/BtnLoading';
import { debounce } from '../../utils/useWindowDimensions';
const fileTypes = ['png', 'jpeg', 'jpg'] // Use MIME type for PNG images

const inistialState = { upload: false, progress: 100, url: null };


const AssetDetails = () => {
  const [addCate, setAddCate] = useState(false);
  const [thumbnail, setThumbnail] = useState(inistialState);
  const params = useParams();
  const { uuid } = params;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [assetType, setAssetType] = useState(threeDAssetType);
  const [name, setName] = useState("");
  const configData = useSelector((state) => state.configSlice)
  const userData = useSelector((state) => state.userSlice);
  const tagsData = useSelector((state) => state.tagsSlice);
  const [currentTab, setCurrentTab] = useState(0);
  const [childTab, setChildTab] = useState(0);
  const [threeDAssetTagsList, setThreeDAssetTagsList] = useState([]);
  const [geometryTagList, setGeometryTagList] = useState([]);
  const [rendererTagList, setRendererTagList] = useState([
              {label: "V-Ray", value: "vray" },
              {label: "Unity 3D", value: "unity3d" }
  ]);
  const [unwrappedTagList, setUnwrappedTagList] = useState([]);
  const [assetAttributesTag, setAssetAttributesTag] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [levelName, setLevelName] = useState(null);
  const [polygons, setPolygons] = useState("");
  const [vertices, setVertices] = useState("");
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  
  const [selectedAssetTag, setSelectedAssetTag] = useState([]);
  const [geometryTag, setGeometryTag] = useState("");
  const [unwrappedUVsTag, setUnwrappedUVsTag] = useState("");
  const [renderer, setRendererUsedTag] = useState("");

  const [description, setDescription] = useState("");
  const [version, setAssetVersion] = useState("");
  const [aboutDescription, setAboutDescription] = useState(
    EditorState.createEmpty()
  );

  const [error, setError] = useState({
    error: false,
    thumbnail: null,
    name: null,
    tags: null,
    geometry:null,
    polygons:null,
    vertices:null,
    unwrapped :null,
    assetAttributes: null,
    renderer: null,
    version: null,
  });

  const assetTagsList = async() => {
    let _data = await assetsTag3DResourceLists();
    setThreeDAssetTagsList(_data?.resourceTag || []);
    setGeometryTagList(_data?.geometryTag || []) ;
    setUnwrappedTagList(_data?.unwrappedTag || []) ;
    setAssetAttributesTag(_data?.assetAttributesTag || []);
  }

  useEffect(() => {
    assetTagsList()
  }, [tagsData])

  const getModuleVersionList = async () => {
    setVersionList([])
    axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/list/versions/${assetType[currentTab].key}/${uuid}`, {
      headers: {
        Authorization: `Bearer ${userData?.token}`,
        "Content-Type ": "application/json"
      }
    }).then((res) => {
      setVersionList(res?.data?.data);
      setRendererUsedTag(rendererTagList.find(e => e.value === res?.data?.data[0]?.data?.renderer))
      setAssetVersion(res?.data?.data[0]?.data?.version || "")
      
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    setLevelName(null);
    getModuleVersionList();
  }, [assetType[currentTab].key])

  const getBasicDetails = async () => {

    try {
      let _data = await assetsTag3DResourceLists();
      let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/details/${uuid}`, {
        headers: {
          "Content-Type ": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        }
      });

      const moduleData = data.data[0];
      setName(moduleData.name);

      let moduleTags = moduleData?.tags?.map((item) => ({
        value: item.id,
        label: item.name,
        categoryId: item.categoryId,
      }));

      setThumbnail({ uploaded: true, url: moduleData.thumbnail });
      setPolygons(moduleData.versions[0].data.polygons);
      setVertices(moduleData.versions[0].data.vertices);
      const description = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(moduleData?.versions[0]?.data?.description || "")));
      setAboutDescription(description);

      setSelectedAssetTag(moduleTags.filter(e => _data?.resourceTag.some(f => f.value === e.value)));
      setGeometryTag(moduleTags.find(e => _data?.geometryTag.find(f => f.value === e.value)));
      setUnwrappedUVsTag(moduleTags.find(e => _data?.unwrappedTag.find(f => f.value === e.value)));
      setSelectedAttributes((moduleTags.filter(e => _data?.assetAttributesTag.some(f => f.value === e.value))).map(m => m.value));
    } catch (error) {
      console.log(error);
    }
  }

  const checkValidation = (assetFile) => {
    let validate = true;

    if (thumbnail.url === null || thumbnail.url.trim() === "") {
      validate = false
      setError(pre => ({ ...pre, thumbnail: "Thumbnail is required." }))
    }
    if (name.trim() === "") {
      validate = false;
      setError(pre => ({ ...pre, name: "Name is required." }))
    }
    if (selectedAssetTag.length === 0) {
      validate = false
      setError(pre => ({ ...pre, tags: "Select at least one tag." }))
    }
    if (geometryTag === "") {
      validate = false
      setError(pre => ({ ...pre, geometry: "GeometryTag are required." }))
    }
    if (polygons.trim().length == 0) {
      validate = false
      setError(pre => ({ ...pre, polygons: "Polygons are required." }))
    }
    if (vertices.trim().length == 0) {
      validate = false
      setError(pre => ({ ...pre, vertices: "Vertices are required." }))
    }
    if (unwrappedUVsTag === "") {
      validate = false
      setError(pre => ({ ...pre, unwrapped: "Unwrapped are required." }))
    }
    if (selectedAttributes.length === 0) {
      validate = false
      setError(pre => ({ ...pre, assetAttributes: "Select at least one Attribute tag." }))
    }
    
    if (assetFile[0].url) {
        if (renderer === "") {
          validate = false
          setError(pre => ({ ...pre, renderer: "Renderer are required." }))
        }
        if (version.trim().length == 0) {
          validate = false
          setError(pre => ({ ...pre, version: "Version are required." }))
        }
    }

    if(!validate){
      setError(pre => ({ ...pre, error: true}))
    }
    
    return validate;

  }

  const detailsSave = async () => {

    setError({
      error: false,
      thumbnail: null,
      name: null,
      tags: null,
      geometry:null,
      polygons:null,
      vertices:null,
      unwrapped :null,
      assetAttributes: null,
      renderer: null,
      version: null,
    });

    setLoading(false)

    
    let filter = assetType[currentTab].types[childTab].types.map((e, i) => ({ level: e.name, url: e.url }));
    
    const filledData = filter.map((item, index, array) => ({
      type: item.level,
      url: item.url || array.find(otherItem => otherItem.url !== null)?.url || null
    }));
    
    if (!checkValidation(filledData)) return;

    let allTagsList = [];


    selectedAssetTag.map((e) => {
      return allTagsList.push(Number(e.value));
    });

    selectedAttributes.map((e) => {
      return allTagsList.push(Number(e));
    });

    if(geometryTag?.value){
      allTagsList.push(Number(geometryTag.value))
    }

    if(unwrappedUVsTag?.value){
      allTagsList.push(Number(unwrappedUVsTag.value))
    }

    const assetDetails = {
      name: name,
      tags: [...allTagsList],
      thumbnail: thumbnail.url,
      assetFilterId: configData.config.assetFilterId,
      polygons: polygons,
      vertices: vertices,
      description: description,

      platform: assetType[currentTab].key,
      type: assetType[currentTab].types[childTab].key,
      assets: filledData,
      renderer: renderer.value,
      version: version
    }
    
      try {
        await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/save/${assetType[currentTab].key}/${uuid}`, assetDetails, {
          headers: {
            "Content-Type ": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          }
        });
        setAssetType(threeDAssetType);
        getBasicDetails();
        getModuleVersionList();
      } catch (error) {
        console.log(error)
      }finally{
        setLoading(false)
      }

  }

  useEffect(() => {
    getBasicDetails();
  }, [uuid,tagsData])

  const handleFileUpload = async (files) => {
    setThumbnail({ ...thumbnail, upload: true })
    const uploadedFile = files;
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      var response = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/temp`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "multipart/form-data"
        }
      });
      setThumbnail({ ...thumbnail, upload: false, url: response.data.url })
    }
  };

  const openCateModal = (name) => {
    setLevelName(name);
    setAddCate(true)
  }

  const closeCateModal = () => {
    setLevelName(null);
    setAddCate(false)
  }

  const handleCancel = () => {
    setThumbnail(inistialState);
  };

  const handleChangeUploadFile = async (file, index) => {
    try {
      const uploadedFile = file;
      const formData = new FormData();
      formData.append('file', uploadedFile);
      setAssetType(prevState => {
        const newState = JSON.parse(JSON.stringify(prevState));
        newState[currentTab].types[childTab].types[index].loading = true;
        newState[currentTab].types[childTab].types[index].fileName = uploadedFile.name;
        newState[currentTab].types[childTab].types[index].fileSize = uploadedFile.size;
        return newState;
      });

      let { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/upload/${assetType[currentTab].key}/${uuid}`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type": "multipart/form-data"
        }
      });

      setAssetType(prevState => {
        let modifyAssetType = JSON.parse(JSON.stringify(prevState));
        modifyAssetType[currentTab].types[childTab].types[index].url = data.url;
        modifyAssetType[currentTab].types[childTab].types[index].fileName = uploadedFile.name;
        modifyAssetType[currentTab].types[childTab].types[index].fileSize = uploadedFile.size;
        modifyAssetType[currentTab].types[childTab].types[index].loading = false;
        return modifyAssetType;
      });

    } catch (error) {
      console.log(error)
    }
  };

  const handlePlatformChange = (e) => {
    setChildTab(0);
    setCurrentTab(e);
    setAssetType(threeDAssetType);
  }

  const handleChildTabChange = (e) => {
    setChildTab(e.target.value);
    setAssetType(threeDAssetType);
  }

  const handleCheckboxChange = (event) => {
    let { id } = event.target;
    id = parseInt(id);
    setSelectedAttributes((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleHandoverAboutChangeInput = (event) => {
    setAboutDescription(event);
    setDescription("" + draftToHtml(convertToRaw(event.getCurrentContent())));
  };

  const cancelAssetsUpload = (index) => {
    let modifyAssetType = JSON.parse(JSON.stringify(assetType));
    modifyAssetType[currentTab].types[childTab].types[index].url = null
    modifyAssetType[currentTab].types[childTab].types[index].file = {}
    modifyAssetType[currentTab].types[childTab].types[index].loading = false
    setAssetType(modifyAssetType);
  }

  function downloadFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    link.setAttribute('download', true);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDownloadFile = (file) => {
    let fileUrl = file.data.assets.filter((e) => e.type.toLowerCase() === (levelName?.toLowerCase() || levelName))[0]?.url || null;
    fileUrl && downloadFile(fileUrl)
  }

  const debounceHandleSaveReview = debounce(detailsSave, 1000, setLoading);


  let filterList = versionList.filter((e) => ((e.data.type == assetType[currentTab].types[childTab].key) && (e.data.platform == assetType[currentTab].key) && (levelName ? e.data.assets.filter((e) => e.type.toLowerCase() === levelName.toLowerCase()) : true)));

  return (
    <>
      <div className="envdetails detailspage container-fluid" style={{ maxWidth: "1500px" }}>
        <div className="mainbox mt-1">
          <div className="row mt-3">
            <div className="col-xl-4 ">

              <div className="form-group ">
                <label className="mb-3 flex-row" htmlFor="exampleFormControlInput1" >
                  File Thumbnail <span>*</span>
                </label>

                <div className="img-view">
                  {thumbnail.url ? (
                    <>
                      <div className='previewimg'  >
                        <img src={thumbnail.url} alt="" />
                        <span onClick={handleCancel} className="canceldelete">
                          <CancelIcon />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className='fileuploader'>
                      <FileUploader
                        name="thumbnailInput"
                        types={['png', 'jpeg', 'jpg']}
                        label="drag & drop or choose file to upload"
                        handleChange={handleFileUpload} // Use onChange instead of onDrop
                      />
                    </div>

                  )}

                </div>

                <div className="uploadpromt">
                  Image Ratio 1:1<br />
                  File format accepted: PNG (Preferably without background)
                  <br />
                  {
                    thumbnail?.upload ? (
                      <div className="uploadProgressBar">
                        <div className="progress mt-2" style={{ height: '10px' }}>
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: `${thumbnail.progress}%`, height: '10px' }}></div>
                        </div>
                        <p>Uploading<span className="dot-animation"></span></p>
                      </div>
                    )
                      :
                      ("")
                  }


                </div>
                <p className='error'>{error.thumbnail}</p>
              </div>

              <div className="form-group mt-0">
                <label className=' flex-row' htmlFor="exampleFormControlInput1">Name <span>*</span></label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control mt-2"
                  id="exampleFormControlInput1"
                  placeholder="Enter File Name"
                />
                <p className='error'>{error.name}</p>

              </div>

              <div className="form-group mt-0">
                <label htmlFor="exampleFormControlInput1">Tags<span>*</span></label>
                <Select
                  isMulti
                  name="jobRoles"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Tags"
                  options={threeDAssetTagsList}
                  value={selectedAssetTag}
                  onChange={setSelectedAssetTag}
                  components={{ NoOptionsMessage: (props) => createNewAssetTags({ ...props, customName: "resourceTag" }) }}
                />
                <p className='error'>{error.tags}</p>
              </div>
            </div>

            <div className="col-xl-8">

              <div className='row'    >
                <div className='col-lg-4'>
                  <div className="form-group mt-0">
                    <label htmlFor="exampleFormControlInput1">Geometry<span>*</span></label>
                    <Select
                      name="geometryTag"
                      className="basic-multi-select mt-2"
                      classNamePrefix="select"
                      placeholder="Select"
                      options={geometryTagList}
                      value={geometryTag}
                      onChange={(e)=>setGeometryTag(e)}
                      components={{ NoOptionsMessage: (props) => createNewAssetTags({ ...props, customName: "geometryTag" }) }}
                    />
                    <p className='error'>{error.geometry}</p>
                  </div>
                </div>
                <div className='col-lg-2'>
                  <div className="form-group mt-0">
                    <label className=' flex-row' htmlFor="exampleFormControlInput1">Polygons <span>*</span></label>
                    <input
                      type="text"
                      value={polygons}
                      onChange={(e) => setPolygons(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter File Name"
                    />
                    <p className='error'>{error.polygons}</p>

                  </div>
                </div>
                <div className='col-lg-2'>
                  <div className="form-group mt-0">
                    <label className=' flex-row' htmlFor="exampleFormControlInput1">Vertices <span>*</span></label>
                    <input
                      type="text"
                      value={vertices}
                      onChange={(e) => setVertices(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter File Name"
                    />
                    <p className='error'>{error.vertices}</p>

                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className="form-group mt-0">
                    <label htmlFor="exampleFormControlInput1">Unwrapped UVs<span>*</span></label>
                    <Select
                      name="unwrappedUVsTag"
                      className="basic-multi-select mt-2"
                      classNamePrefix="select"
                      placeholder="Select"
                      options={unwrappedTagList}
                      value={unwrappedUVsTag}
                      onChange={(e)=>setUnwrappedUVsTag(e)}
                      components={{ NoOptionsMessage: (props) => createNewAssetTags({ ...props, customName: "unwrappedTag" }) }}
                    />
                    <p className='error'>{error.unwrapped}</p>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="form-group mt-0 pt-0">
                    <label htmlFor="exampleFormControlInput1">Choose Asset Attributes:<span>*</span></label>
                    <div className='threed-list'>
                    {assetAttributesTag.map((item, index) => (
                      <div key={item.value} className="form-check listchild quizhead pt-3 ps-2" style={{ cursor: "auto" }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={item.value}
                          value={item.label}
                          checked={selectedAttributes.includes(item.value)} 
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label cursor-pointer" htmlFor={item.value}>
                            {item.label}
                        </label>
                      </div>
                    ))}
                    </div>
                    <p className='error'>{error.assetAttributes}</p>
                  </div>
                </div>
                <div className="col-md-12">
              <div className="form-group pt-3">
                <label htmlFor="exampleFormControlDescription" className="form-label pb-2">
                  Description
                </label>
                <div className="modalDescription threed-modal">
                <Editor
                    name="project_about"
                    id="exampleFormControlDescription"
                    editorState={aboutDescription}
                    stripPastedStyles={true}
                    wrapperClassName="demo-wrapper"
                    className="mt-2"
                    editorClassName="demo-editor"
                    onEditorStateChange={handleHandoverAboutChangeInput}
                    toolbar={{
                      options: ["inline", "remove", "history"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline", "strikethrough"],
                      },
                    }}
                  />
                  </div>
              </div>
              </div>
              </div>




              <div className="popupselect fortype">
                <p>Platform</p>
                <div className="radio-inputs">
                  <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                    <RadioGroup
                      row
                      aria-labelledby="price-visibility-label"
                      name="questionType"
                      className="gap-3"
                      value={currentTab}
                      onChange={(e) => handlePlatformChange(e.target.value)}
                    >

                      {assetType.map((element, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            className="quizlabel"
                            value={index}
                            control={<Radio />}
                            label={element.name}
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              

              {assetType[currentTab] ?
                <div className="popupselect fortype">
                  <p>Asset Type</p>
                  <div className="radio-inputs">
                    <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                      <RadioGroup
                        row
                        aria-labelledby="price-visibility-label"
                        name="questionType"
                        className="gap-3"
                        value={childTab}
                        onChange={handleChildTabChange}
                      >
                        {assetType[currentTab].types.map((e, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              className="quizlabel"
                              value={index}
                              control={<Radio />}
                              label={e.name}
                            />)

                        })}

                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                : null}

               <div className='row'>
            
                <div className='col-lg-4'>
                <div className="form-group mt-0">
                    <label htmlFor="exampleFormControlInput1">Renderer Used (if uploading file)<span>*</span></label>
                    <Select
                      name="RendererUsed"
                      className="basic-multi-select mt-2"
                      classNamePrefix="select"
                      placeholder="Select"
                      options={rendererTagList}
                      value={renderer}
                      onChange={(e)=>setRendererUsedTag(e)}
                      // components={{ NoOptionsMessage: (props) => createNewAssetTags({ ...props, customName: "rendererTag" }) }}
                    />
                    <p className='error'>{error.renderer}</p>
                  </div>
                </div>
                <div className='col-lg-4'>
                <div className="form-group mt-0">
                    <label className=' flex-row' htmlFor="exampleFormControlInput1">Version (if uploading file)<span>*</span></label>
                    <input
                      type="text"
                      value={version}
                      onChange={(e) => setAssetVersion(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter Version"
                    />
                    <p className='error'>{error.version}</p>

                  </div>
                </div>
               </div>


              {assetType[currentTab] && assetType[currentTab].types[childTab] && assetType[currentTab].types[childTab].types.length ?
                (assetType[currentTab].types[childTab].types.map((e, index) => {
                  return (
                    e.loading ?
                      <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                        <div className='fileuploader mb-3'>
                          <div className='filetag'>
                            {e.name}
                          </div>
                          <label className='assets-upload'>
                            <div className='uploadstatus'>
                              <div className='upperdiv'>
                                <div className='uploaddata'>
                                  <div>
                                    <span className='stats withname'>
                                      <p> {e.fileName} </p>
                                    </span>
                                    <span className='stats withname'> <p> {convertFileSize(e.fileSize)} </p> </span>
                                  </div>
                                  <div className="uploadProgressBar">
                                    <div className="progress mt-2" style={{ height: '10px' }}>
                                      <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", height: '10px' }}></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      :
                      e.url ?
                        <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                          <div className='fileuploader mb-3'>
                            <div className='filetag'>
                              {e.name}
                            </div>
                            <label className='assets-upload'>
                              <div className='uploadstatus'>
                                <div className='upperdiv'>
                                  <div className='uploaddata'>
                                    <div>
                                      <span className='stats withname'>
                                        <p> {e.fileName} </p>
                                        <span onClick={() => cancelAssetsUpload(index)} >
                                          <CancelIcon />
                                        </span>
                                      </span>
                                      <span className='stats withname'> <p> {convertFileSize(e.fileSize)} </p> </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                        :
                        <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                          <div className='fileuploader  mb-3'>
                            <div className='filetag'>
                              {e.name}
                            </div>
                            <FileUploader
                              handleChange={(file) => handleChangeUploadFile(file, index)}
                              label={"drag & drop or choose file to upload"}
                              name="file"
                            />
                            <span className='filetag version' onClick={() => openCateModal(e.name)}>
                              Version history
                            </span>
                          </div>
                        </div>

                  );
                }
                ))
                : null}

            </div>

          </div>

        </div>
        {userData?.user?.permissions?.includes("CONTENT.3D.CREATE") &&
          <div className="col-md-12 pe-2 mt-4">
            <div className="footerbtn text-end">
               {error.error && <p className='error'> Kindly fill all necessary fields.</p>}
            </div>
            <div className="footerbtn text-end">
              <button onClick={() => { setAssetType(threeDAssetType); navigate(-1) }}>Cancel</button>
              <button onClick={debounceHandleSaveReview}>
                {
                  loading ? <BtnLoading /> : "Save"
                }
              </button>
            </div>
          </div>
        }
      </div>

      <Modal show={addCate} size='lg' centered onHide={() => closeCateModal()}>
        <Modal.Header className='modalheader'>
          Version History
          <span style={{ cursor: "pointer" }} onClick={() => closeCateModal()} ><CloseIcon /></span>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className='row'>
            <div className='col-md-12'>
              <span className='version-tag'> {assetType[currentTab].name} <BreadRightArrow /> {assetType[currentTab].types[childTab].name} <BreadRightArrow /> {levelName} </span>
            </div>
            <div className="table-responsive workflowtb mt-2 stickyhead ">
              <table className="table align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-center">Version Name</th>
                    <th className="text-center">Type</th>
                    <th className="text-center" style={{ cursor: "pointer" }}>Timestamp  {/* <UpDownIcon /> */}</th>
                    <th>Uploaded By</th>
                  </tr>
                </thead>
                <tbody className='versiontable'>
                  {filterList.map((item, index) => {
                    let avatar = item?.activities[0]?.users[0]?.avatar || null;
                    return (
                      <tr key={index}>
                        <td className='text-center versiontag' onClick={() => handleDownloadFile(item)}>  {item.version.toString()} </td>
                        <td className="text-center"> {item.data.type} </td>
                        <td className="text-center"> {moment(item.createdAt).format("lll")}</td>

                        <td className="d-flex justify-content-start gap-2 align-items-center" style={{ fontSize: "14px" }}>
                          <span className='placeholder_'>
                            {avatar && <img alt='' src={avatar} referrerPolicy={'no-referrer'} />}
                          </span>
                          {item?.activities[0]?.users[0]?.name || null}
                        </td>

                      </tr>
                    )
                  }

                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default AssetDetails;