import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import LottiePlayer from "lottie-react";
import NoDataFoundAnim from '../animations/nodatafound.json'
import { CloseIcon, FolderIcon } from '../Static/icons/iconshere';

const LinkModal = ({moduleTitle, showLinkModel, handleCloseLinkModal, assetsList, categoryList, hierarchyList, getCategoryList, getCategoryAssetList, linkedAssetList, handleSelectLinkedResource }) => {

    const [noModels, setNoModels] = useState(false);

    const handleClickCategory = (id)=>{
        getCategoryList(id);
        getCategoryAssetList(id);
    }

    return (
        <Modal show={showLinkModel} size="xl" className='moving-box' centered  onHide={()=>handleCloseLinkModal(false)}>
            <Modal.Header className="modalheader">
                Select Models for "{moduleTitle}"
                <span onClick={()=>handleCloseLinkModal(false)}>
                    <CloseIcon />
                </span>
            </Modal.Header>

            <Modal.Body className='pt-0'>
                <div className='group-breadcrumbs pt-2'>
                    {hierarchyList.map((item)=>{
                        return(
                            <div key={item.id}> 
                            <div className='group-breadcrumbs-item' onClick={()=>handleClickCategory(item.id)}>
                                {item.name}
                            </div>
                            <div className='separate'> &gt; </div>
                           </div>
                        )
                    })}
                </div>

                <div className={noModels ? 'empty model-list' : 'model-list'}>
                    {noModels ?
                        <>
                            <div className='no-group-found'>
                                <LottiePlayer
                                    animationData={NoDataFoundAnim}
                                    style={{ height: 100, marginBottom: 0 }}
                                />
                                <p>No Group Found</p>
                            </div>
                        </>
                        :
                        <>

                            <div className='model-list-here'>
                                  {categoryList.map((item)=>{
                                    return(
                                        <div className='folder-path' key={item.id} onClick={()=>handleClickCategory(item.id)}>
                                            <FolderIcon />
                                            <p className='name'>
                                                {item.name}
                                            </p>
                                        </div> 
                                    )
                                  })}

                                  {assetsList.map((item)=>{
                                     const isChecked = linkedAssetList.some(linkedItem => linkedItem.uuid === item.uuid);
                                    return(
                                        <div key={item.uuid} className='model' onClick={()=>handleSelectLinkedResource({name: item.name, uuid: item.uuid})}>
                                            <p className='name'>
                                                {item.name}
                                            </p>
                                            <img src={item?.thumbnail} alt={item.name} />
                                            <div className="form-check listchild">
                                                <input checked={isChecked} className="form-check-input" name="basicDetails" type="checkbox" id={item.uuid} />
                                                <label className="form-check-label" htmlFor={item.uuid}>
                                                </label>
                                            </div>
                                        </div>
                                    )
                                  })} 
                                                                 
                            </div>

                        </>
                    }
                </div>
            
                 <div className='selected-items'>
                    <p>Selected Models :</p>
                    {linkedAssetList.map((item)=> (
                        <span key={item.uuid} className='selectedlist' onClick={()=>handleSelectLinkedResource(item)}>
                            <span className='select-item'>
                                {item.name} 
                                <span className='close'>
                                    <CloseIcon/>
                                </span>
                            </span>
                        </span>
                    ))}
                 </div>
            
                <div className='modal-footer-btn mt-3 justify-content-between mb-0'>
                    <span className='text-success text-bold' style={{ fontSize: "0.85rem" }}> Moved Successfully! </span>
                    <div className='d-flex align-items-center gap-2 justify-content-end'>

                        <button className='createbtn cancelbtn' onClick={()=>handleCloseLinkModal(false)}>
                            Cancel
                        </button>

                        <button className='createbtn' onClick={()=>handleCloseLinkModal(true)}>
                            Save
                        </button>

                        {/* <button className="loading save">
                            <svg viewBox="25 25 50 50">
                                <circle r="20" cy="50" cx="50">{""}</circle>
                            </svg>
                        </button> */}
                    </div>
                </div>
            
            
            </Modal.Body>

        </Modal>
    )
}

export default LinkModal
