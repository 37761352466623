import { addModuleXRTags, addAssetTags, addSkillStream2DTags } from '../../store/slice/tagsSlice';
import { addModules } from '../../store/slice/moduleSlice';
import { store } from '../../store/store';
import { assetFilterId } from '../../env';
import apiClient from '../../apiClient';
import { CREATE_TAG, TAG_LIST, XR_MODULE_LIST } from '../../apiClient/apiEndpoints';

export const getAllXRModuleTags = async () => {
         try {
             let _config = store.getState().configSlice;
             let moduleFilterId = _config?.config?.moduleFilterId;
             let UNO_URL = _config?.config.UNO_URL;
     
             let data = await apiClient.get(`${UNO_URL}${TAG_LIST}/${moduleFilterId}`);        
             store.dispatch(addModuleXRTags(data)) ;

         } catch (error) {
             console.log(error);
         }

}

export const getAllSkillStream2DTags = async () => {
         try {
             let _config = store.getState().configSlice;
             let skillStream2DFilterId = _config?.config?.skillStream2DFilterId;
             let UNO_URL = _config?.config.UNO_URL;
     
             let data = await apiClient.get(`${UNO_URL}${TAG_LIST}/${skillStream2DFilterId}`);
             store.dispatch(addSkillStream2DTags(data));
            
         } catch (error) {
             console.log(error);
         }

}

export const getAssetTagsList = async (assetFilterId) => {
    try {
        let _config = store.getState().configSlice;
        let UNO_URL = _config?.config.UNO_URL;
        let data = await apiClient.get(`${UNO_URL}${TAG_LIST}/${assetFilterId}`);        
        store.dispatch(addAssetTags(data));
               
    } catch (error) {
         console.log(error);
    }
}

export const assetsTag3DResourceLists = async() => {
    let children = store.getState().tagsSlice?.assetTagsList?.children || [];
    let result = {};
    children.forEach(item => {
        result[item.name] = item.tags.map(tag => ({
            label: tag.name,
            value: tag.id,
            categoryId: tag.categoryId
              }));
        });

    return result

}

export const getAllModuleList = async () => {
         try {
             let _config = store.getState().configSlice;
             let UNO_URL = _config?.config.UNO_URL;
             let data = await apiClient.get(`${UNO_URL}${XR_MODULE_LIST}`);
             store.dispatch(addModules(data))

         } catch (error) {
            console.log(error);
         }

}

export const createNewCustomTags = (props) => {
    const { innerProps, selectProps, customName, type } = props;
    
    const handleCreateNewTag = async() => {
        let UNO_URL = store.getState().configSlice?.config.UNO_URL;
        let tagSliceName =  type === "SKILL_STREAM" ? "skillStream2D" : "allTagsList";
        const _findCategory = store.getState().tagsSlice?.[tagSliceName]?.children?.find(item => item.name === `${customName}`);
        let createTag = {
            "name": selectProps.inputValue,
            "categoryId": _findCategory.id
        }

        try {
            await apiClient.post(`${UNO_URL}${CREATE_TAG}`, createTag);
            getAllXRModuleTags();
            getAllSkillStream2DTags();
        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            <div className='nodatafoundbtn' {...innerProps}>
                <p>No options found.</p>
                <button onClick={handleCreateNewTag}>Create This Tag</button>
            </div>
        </>
    );
};

export const createNewAssetTags = (props) => {
    const { innerProps, selectProps, customName } = props;
    
    const handleCreateNewTag = async() => {
        let UNO_URL = store.getState().configSlice?.config.UNO_URL;
        
        let _token = store.getState().userSlice.token

        const _findCategory = store.getState().tagsSlice?.assetTagsList?.children?.find(item => item.name === customName);
        
        let createTag = {
            "name": selectProps.inputValue,
            "categoryId": _findCategory.id
        }

        try {
            await apiClient.post(`${UNO_URL}${CREATE_TAG}`, createTag);
        } catch (error) {
            console.log(error);
        }

        getAssetTagsList(assetFilterId);

    };

    return (
        <>
            <div className='nodatafoundbtn' {...innerProps}>
                <p>No options found.</p>
                <button onClick={handleCreateNewTag}>Create This Tag</button>
            </div>
        </>
    );
};

export const moduleTagCategoryWithTags = () => {

    const result = { categories: [] };

    const categoriesConfig = [
        { name: 'Sectors', key: '_sectorsList' },
        { name: 'Packages', key: '_packagesList' },
        { name: 'Job Roles', key: '_jobRolesTags' },
        { name: 'Languages', key: '_languagesTags' },
        { name: 'Difficulty Level', key: '_difficultyLevelTags' },
        { name: 'Validated By', key: '_ValidatedByLevelTags' },
        { name: 'Tags', key: '_Tags' },
    ];

    const allTagCategoryList = store.getState().tagsSlice?.allTagsList?.children || [];

    categoriesConfig.forEach(({ name, key }) => {
        const category = allTagCategoryList.find(item => item.name === name);

        result[key] = category?.tags?.map(tag => ({
            label: tag.name,
            value: tag.id,
            categoryId: category.id,
        })) || [];
        
        result.categories.push(category);

    });

    return result;

}


export const skillStream2DTagCategoryWithTags = () => {

    const result = { categories: [] };

    const categoriesConfig = [
        { name: 'Category', key: '_categoryLevelTags' },
    ];

    const allTagCategoryList = store.getState().tagsSlice?.skillStream2D?.children || [];

    categoriesConfig.forEach(({ name, key }) => {
        const category = allTagCategoryList.find(item => item.name === name);

        result[key] = category?.tags?.map(tag => ({
            label: tag.name,
            value: tag.id,
            categoryId: category.id,
        })) || [];
        
        result.categories.push(category);

    });

    return result;
}
