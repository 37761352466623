import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Tooltip from '@mui/material/Tooltip';
import Button from 'react-bootstrap/Button';
import logo from '../../Static/images/logo.png';
import { AddIcon, BreadRightArrow, CateIcon, CloseIcon, DeleteIcon, EditIcon, FolderIcon, LeftArrow, NewFolderIcon, SearchesCIcon } from '../../Static/icons/iconshere';
import { usePageTitle } from '../PageTitleContext';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoFiles from '../NoFiles';
import apiClient from '../../apiClient';
import { CATEGORY_CATEGORIES_LIST } from '../../apiClient/apiEndpoints';


const initialCategory = {
    show: false,
    name: null,
    metaData: {},
    error: null
}

const initialAsset = {
    name: null,
    show: false,
    nameError: null
}

const AssetsChildDashboard = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { id, parentId } = params;
    const [newCategory, setNewCategory] = useState(initialCategory);
    const [newAsset, setNewAsset] = useState(initialAsset);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [assetsList, setAssetsList] = useState([]);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);
    const [heirarchy, setHierarchy] = useState([]);
    const [search, setSearch] = useState("");
    const [name, setName] = useState();
    const [chilAssets, setChildAssets] = useState([]);
    const inputRef = useRef(null)
    useEffect(() => {
        if (newAsset.show || newCategory.show) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [newAsset.show, newCategory.show]);

    const closeAssetModal = () => {
        setNewAsset(initialAsset);
    };


    const getBasicDetails = async () => {
        try {
            let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/details/${id}`, {
                headers: {
                    "Content-Type ": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            setName(data.data[0].name)
            setAssetsList(data.data)
        } catch (error) {
            console.log(error);
        }
    };

    const getChildAssets = async () => {
        try {
            let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/3d/child/asset/list/${id}`, {
                headers: {
                    "Content-Type ": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            setChildAssets(data.data)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchSubCategories = async (categoryId) => {
        try {
            let data = await apiClient.get(`${configData.config.UNO_URL}${CATEGORY_CATEGORIES_LIST}/${categoryId}`);
            setHierarchy(data.heirarchy);
            setLoading(false)
        } catch (error) {
            console.log(error);
        }

    }

    const saveAsset = async () => {
        if (newAsset.name === null) {
            setNewAsset({ ...newAsset, nameError: "Please enter asset name." })
            return
        }
        setSaving(true);
        try {
            var { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/createChild`, { name: newAsset.name, tags: [], parentId: id, categoryId: parseInt(parentId) }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            if (data.status === true) {
                setNewAsset(initialAsset);
                navigate(`/dashboard/3d/details/${data.data.uuid}`, {state:{name: data.data.name, type: "3DASSET", parentId: parentId }} )
            }
        } catch (error) {
            if (!error.response.data.status) setNewAsset({ show: true, nameError: error.response.data.message })
            console.log(error);
        }
        setSaving(false);
    }

    useEffect(() => {
        getBasicDetails();
        fetchSubCategories(parentId)
        getChildAssets()
    }, [id])


    return (
        <>
            <div className='stuckhere'>
                <div className="row">
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                            <div className="position-relative">
                                <SearchesCIcon />
                                <input
                                    type="text"
                                    name="searchName"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search "
                                    autoComplete="off"
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-md-6  d-flex align-items-center justify-content-end mainbtns">
                        <button onClick={() => setNewAsset({ ...newAsset, show: true })} className="createbtn">Add Child</button>
                    </div>

                </div>
                <div className='row mt-4'>
                    {heirarchy.length > 1 ? <div className='col-md-12 d-flex align-items-center justify-content-start'>
                        <div className='nav-btw'>
                            <span onClick={() => navigate(`/dashboard/3d/${heirarchy[heirarchy.length - 1].id}`)}>
                                <LeftArrow />
                            </span>
                        </div>
                        <div className='breadcrums'>
                            {!loading ? heirarchy.map((item, index) => {
                                return <span onClick={() => navigate(`/dashboard/3d/${item.id}`)} key={index} >
                                    <Tooltip title={item.name}>
                                        <span> {item.name}</span>
                                    </Tooltip>
                                    {item.id === parseInt(id) ? null : <BreadRightArrow />} </span>
                            }) : null}
                            <span className='active'>
                                <Tooltip title={name} >
                                    <span>
                                        {name}
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                    </div> : null}
                </div>
            </div>

            <div className='px-3 environments for-child'>
                <div className='col-md-12 filesndfolders assets mt-4 px-2'>
                    <h3 className='mb-3'> Parent Asset</h3>

                    <div className='course-tiles'>
                  
                            <>
                                {assetsList.map((elem) => {
                                    if (search.length > 0 && !elem.name.toLowerCase().includes(search.toLowerCase())) return null;
                                    let avatar = elem?.activities[0]?.users[0]?.avatar || null;
                                    let userName  = elem?.activities[0]?.users[0]?.name || null;
                                    let name  = userName ? userName.split(" ").slice(0,2).map(e => e.split("")[0]).join("").toUpperCase() : "";
                                    return (
                                        <div key={elem.uuid} className='tilecard' onClick={() => navigate(`/dashboard/3d/details/${elem.uuid}`, {state:{name: elem.name, type: "3DASSET", parentId: parentId }})}>
                                            <div className='assetsbox'>
                                                <div className='preview'>
                                                    <img
                                                        src={elem.thumbnail || logo}
                                                        onError={(e) => {
                                                            e.target.src = logo;
                                                            e.onerror = null;
                                                        }}
                                                        alt='no-referrer'
                                                        referrerPolicy={'no-referrer'}
                                                    />
                                                </div>
                                                <div className='details'>
                                                    <Tooltip title={elem.name}>
                                                        <p>{elem.name}</p>
                                                    </Tooltip>
                                                    <p>
                                                        <Tooltip title={"Last updated at " + moment(elem.updatedAt).format("lll")}>
                                                            {moment(elem.createdAt).fromNow()}
                                                        </Tooltip>
                                                        <Tooltip title={elem?.activities[0]?.users[0]?.name || ""}>
                                                            <span className='placeholder_'>
                                                                {avatar ? <img alt='' src={avatar} referrerPolicy={'no-referrer'} /> : name.toUpperCase()}
                                                            </span>
                                                        </Tooltip>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                      
                         </div>
                </div>


                <div className='col-md-12 filesndfolders assets mt-4 px-2'>
                    <h3 className='mb-3'> Child Assets</h3>
                        {chilAssets.length > 0 ? (
                    <div className='course-tiles'>
                            <>
                                {chilAssets.map((elem) => {
                                    if (search.length > 0 && !elem.name.toLowerCase().includes(search.toLowerCase())) return null;
                                    let avatar = elem?.activities[0]?.users[0]?.avatar || null;
                                    let userName  = elem?.activities[0]?.users[0]?.name || null;
                                    let name  = userName ? userName.split(" ").slice(0,2).map(e => e.split("")[0]).join("").toUpperCase() : "";

                                    return (
                                        <div key={elem.uuid} className='tilecard' onClick={() => navigate(`/dashboard/3d/details/${elem.uuid}`, {state:{name: elem.name, type: "3DASSET", parentId: parentId }})}>
                                            <div className='assetsbox'>
                                                <div className='preview'>
                                                    <img
                                                        src={elem.thumbnail || logo}
                                                        onError={(e) => {
                                                            e.target.src = logo;
                                                            e.onerror = null;
                                                        }}
                                                        alt='no-referrer'
                                                        referrerPolicy={'no-referrer'}
                                                    />
                                                </div>
                                                <div className='details'>
                                                    <Tooltip title={elem.name}>
                                                        <p>{elem.name}</p>
                                                    </Tooltip>
                                                    <p>
                                                        <Tooltip title={"Last updated at " + moment(elem.updatedAt).format("lll")}>
                                                            {moment(elem.createdAt).fromNow()}
                                                        </Tooltip>
                                                        <Tooltip title={elem?.activities[0]?.users[0]?.name || ""}>
                                                            <span className='placeholder_'>
                                                                {avatar ? <img alt='' src={avatar} referrerPolicy={'no-referrer'} /> : name.toUpperCase()}
                                                            </span>
                                                        </Tooltip>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                    </div>
                    ) : (
                        <div className='No-Cat-Asset'>                      
                        <NoFiles message={"No child asset found"}/>         
                        </div>     
                        )}
                </div>

                <Modal show={newAsset.show} size='md' centered onHide={() => closeAssetModal()}>
                    <Modal.Header className='modalheader'>
                        New Asset
                        <span onClick={() => closeAssetModal()} ><CloseIcon /></span>
                    </Modal.Header>
                    <Modal.Body className='pb-0'>
                        <div className='row'>
                            <div className='col-12 standardinput' >
                                <label>
                                    File Name
                                    <input ref={inputRef} autoComplete="off" value={newAsset.name} onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value, nameError: null })} name="moduleName" id="moduleName" className="input form-control" type="text" />
                                    <p className='error'>{newAsset.nameError}</p>
                                </label>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className='footerbtn'>
                        <Button variant="secondary" onClick={closeAssetModal}> Cancel </Button>
                        <Button disabled={saving} variant="primary" onClick={() => saveAsset()}> Save </Button>
                    </Modal.Footer>
                </Modal>


            </div>

        </>
    )
}

export default AssetsChildDashboard;