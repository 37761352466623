import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';


const AssetsTabs = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { uuid } = useParams();
    const Child = props.ChildComponent;
    const { tabName } = props;
   const locationData = location.state || {};
   const [moduleName, setModuleName] = useState("");
   const [parentId, setParentId] = useState(null);


    const handleTabSelect = (selectedTab) => {
        if (selectedTab === "back") {
            navigate(`/dashboard/3d/${parentId}`)
        }else{
            navigate(`/dashboard/3d/${selectedTab}/${uuid}`, { state: { name: moduleName, type: "3DASSET", parentId: parentId } })
         }
    };

    useEffect(() => {
        setModuleName(locationData?.name || "");
        setParentId(locationData?.parentId || null);
     }, [locationData])

    return (
        <>
            <div className='filterhere'>

                <Nav variant="tabs" activeKey={tabName} onSelect={handleTabSelect}>
                    <Nav.Item>
                        <Nav.Link eventKey="back"><span><i className="bi-chevron-left"></i> Back </span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="details">Upload Assets</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="channels">Publish</Nav.Link>
                    </Nav.Item>
                </Nav>

            </div>
            <div className='allcompages mt-3'>
                <Child {...props}></Child>
            </div>
        </>
    )
}
export default AssetsTabs