import OculusIcon from '../Static/images/oculusnew.svg'
import VrNEw from '../Static/images/vrnew.svg'
import MobileNew from '../Static/images/mobilenew.svg'
import DesktopIcon from '../Static/images/desktopnew.svg'
import WebglNew from '../Static/images/webglnew.svg'

export const modulePlatforms = [
    {
        "name": "Standalone VR",
        "key": "standalonevr",
        "icon": OculusIcon
    },
    {
        "name": "Mobile",
        "key": "mobile",
        "icon": MobileNew
    },
    {
        "name": "Desktop VR",
        "key": "desktopvr",
        "icon": VrNEw
    },
    {
        "name": "Desktop",
        "key": "desktop",
        "icon": DesktopIcon
    },
    {
        "name": "WebGL",
        "key": "web",
        "icon": WebglNew
    },
    
];

export const environmentPlatforms = [
    {
        "name": "Standalone VR",
        "key": "standalonevr"
    },
    {
        "name": "Mobile",
        "key": "mobile"
    },
    {
        "name": "Desktop VR",
        "key": "desktopvr"
    },
    {
        "name": "Desktop",
        "key": "desktop"
    },
    {
        "name": "WebGL",
        "key": "web"
    },
    {
        "name": "iOS",
        "key": "iOS"
    },
    {
        "name": "MacOS",
        "key": "macOS"
    }
];

export const details = [
    {
        "key": "basicDetails",
        "name": "Basic Details"
    },

    {
        "key": "passingCriteria",
        "name": "Passing Criteria"
    },

    {
        "key": "video",
        "name": "Video"
    },
    {
        "key": "quiz",
        "name": "Quiz"
    },

    {
        "key": "sop",
        "name": "SOP"
    },
    {
        "key": "tasks",
        "name": "Task List"
    },
]

export const renameKeys = (obj) => {
    const keyMap = {
        'Id': 'id',
        'Critical': 'isCritical',
        'Time': 'plannedTime',
        'Priority': 'priority',
        'Description': 'taskDesc',
        'Title': 'title',
    };

    return Object.keys(obj).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        return { ...acc, [newKey]: obj[key] };
    }, {});
};

export const convertFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const threeDAssetType = [
    {
        "name": "Web",
        "key": "web",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Mobile",
        "key": "mobile",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Desktop",
        "key": "desktop",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Desktop VR",
        "key": "desktopvr",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Standalone VR",
        "key": "standalonevr",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Mac OS",
        "key": "macOS",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "iOS",
        "key": "iOS",
        types: [
            {
                "key": "addressable",
                "name": "Addressable",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            },
            {
                "key": "ab",
                "name": "Asset Bundle",
                types: [
                    {
                        "key": "Level 0",
                        "name": "Level 0",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 1",
                        "name": "Level 1",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                    {
                        "key": "Level 2",
                        "name": "Level 2",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    },
                ]
            }
        ]
    },
    {
        "name": "Universal",
        "key": "universal",
        types: [
            {
                "key": "fbx",
                "name": "FBX",
                types: [
                    {
                        "key": "fbx",
                        "name": "FBX",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    }
                ]
            },
            {
                "key": "gltf",
                "name": "GLTF",
                types: [
                    {
                        "key": "gltf",
                        "name": "GLTF",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    }
                ]
            },
            {
                "key": "glb",
                "name": "GLB",
                types: [
                    {
                        "key": "glb",
                        "name": "GLB",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    }
                ]
            },
            {
                "key": "gfb",
                "name": "GFB",
                types: [
                    {
                        "key": "gfb",
                        "name": "GFB",
                        url: null,
                        loading: false,
                        fileName: null,
                        fileSize: null
                    }
                ]
            }
        ]
    },
]

export const syncResourceTypeText = {
    "MODULE": "Basic Details on channel ",
    "MODULE.TASKS": "Module Tasks and passing criteria on channel ",
    "MODULE.VIDEO": "Video on channel ",
    "MODULE.QUIZ": "Quiz on channel ",
    "MODULE.SOP": "SOPs on channel ",
    "MODULE.ASSET.STANDALONE.VR": "Standalone VR Assets on Channel ",
    "MODULE.ASSET.DESKTOP.VR": "Desktop VR Assets on Channel ",
    "MODULE.ASSET.WEB": "WebGL Assets on Channel ",
    "MODULE.ASSET.MOBILE": "Mobile Assets on channel ",
    "MODULE.ASSET.DESKTOP": "Desktop Assets on Channel ",

    "3DASSET": "Basic Details on channel ",
    "3D.ASSET.WEB": "WebGL Assets on Channel ",
    "3D.ASSET.MOBILE": "Mobile Assets on channel ",
    "3D.ASSET.STANDALONE.VR": "Standalone VR Assets on Channel ",
    "3D.ASSET.UNIVERSAL": "Universal Assets on Channel ",
    "3D.ASSET.DESKTOP": "Desktop Assets on Channel ",
    "3D.ASSET.DESKTOP.VR": "Desktop VR Assets on Channel ",
    "3D.ASSET.MACOS": "MacOS Assets on Channel ",
    "3D.ASSET.IOS": "Ios Assets on Channel ",

    "ENVIRONMENT": "Basic Details on channel ",
    "ENVIRONMENT.DYNAMIC.CONTENT": "Dynamic Content on channel ",
    "ENVIRONMENT.ASSET.MOBILE": "Mobile Assets on channel ",
    "ENVIRONMENT.ASSET.STANDALONE.VR": "Standalone VR Assets on Channel ",
    "ENVIRONMENT.ASSET.DESKTOP.VR": "Desktop VR Assets on Channel ",
    "ENVIRONMENT.ASSET.DESKTOP": "Desktop Assets on Channel ",
    "ENVIRONMENT.ASSET.WEB": "WebGL Assets on Channel ",
    "ENVIRONMENT.ASSET.MACOS": "MacOS Assets on Channel ",
    "ENVIRONMENT.ASSET.IOS": "Ios Assets on Channel ",

    "2D": "Basic Details on channel ",
    "2D.SECTION": "Section Details on channel ",
    "2D.ASSET": "Assets on channel ",
    "2D.SOP": "Sops on channel ",
}

export const operationStatus = {
    "INQUEUE" : "waiting",
    "INPROGRESS" : "inprocess",
    "COMPLETED" : "completed",
    "FAILED" : "failed"
}