import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "aos/dist/aos.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import AddTask from "./AddTaskModal/AddTask";
import EditTask from "./editTask";
import {
  AddIcon,
  DragListIcon,
  EditIcon,
  SettingIcon,
  SortupdownIcon,
  UploadIcon,
} from "../../Static/icons/iconshere";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { usePageTitle } from "../PageTitleContext";
import useWindowDimensions from "../../utils/useWindowDimensions";
import CsvTaskModule from "./csvTaskModule";
import ScenariosList from "./scenariolist";
import apiClient from "../../apiClient";
import { XR_MODULE_TASK_SCENARIO } from "../../apiClient/apiEndpoints";

const Tasklist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [drag, setDrag] = useState(false);
  const params = useParams();
  const [locked, setLocked] = useState(false);
  const [reorder, setReorder] = useState(false);
  const moduleUUID = params?.uuid;
  const [moduleName, setModuleName] = useState(null);
  const [moduleID, setModuleID] = useState(null);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showEditTask, setShowEditTask] = useState(false);
  const [showCsvModule, setShowCsvModule] = useState(false);
  const [showScenario, setShowScenario] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [moduleTaskList, setModuleTaskList] = useState([]);
  const [scenarioList, setScenarioList] = useState([]);
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle("XR Modules");
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const handleShowAddTask = () => setShowAddTask(true);
  const handleCloseAddTask = () => setShowAddTask(false);

  const handleShowScenario = () => setShowScenario(true);
  const handleCloseScenario = () => setShowScenario(false);

  const handleCloseEditTask = () => setShowEditTask(false);
  const handleCloseCsvModule = () => setShowCsvModule(false);

  const handleShowEditTask = (data) => {
    setShowEditTask(true);
    setEditValue({ ...data });
  };
  

  const addNewTaskToList = (newTask) => {
    let taskList = [...moduleTaskList, newTask];
    // taskList.sort((a, b) => Number(a.id) - Number(b.id));
    // taskList.sort((a, b) => a.priority - b.priority);

    if (locked) {
      taskList = taskList.map((item) => ({
        ...item,
        id: Number(item.id),
        priority: Number(item.priority),
      }));
    } else {
      taskList = taskList.map((item, index) => ({
        ...item,
        id: Number(index + 1),
        priority: Number(item.priority),
      }));
    }
    setModuleTaskList(taskList);
    handleCloseAddTask();
    handleSaveAndProceed(taskList);
  };

  const saveEditTask = (value) => {
    var taskList = [...moduleTaskList];
    var index = taskList.findIndex((e) => e.id == value.id);
    if (index !== -1) {
        taskList[index] = value;
        // taskList.sort((a, b) => a.priority - b.priority);

        if (locked) {
          taskList = taskList.map((item) => ({
                ...item,
                priority: Number(item.priority),
          }));
        } else {
          taskList = taskList.map((item) => ({
                ...item,
                id: Number(item.id),
                priority: Number(item.priority),
          }));
        }

      setModuleTaskList(taskList);
      handleSaveAndProceed(taskList);
    }
    handleCloseEditTask();
  };

  useEffect(() => {
    if (moduleUUID.length) {
      getModuleTaskList();
    }
  }, [moduleUUID]);


  const handleSaveAndProceed = async (taskList) => {
    try {
      let data = {
        tasks: Array.isArray(taskList) ? taskList : moduleTaskList
      };

      await apiClient.post(`${configData.config.UNO_URL}${XR_MODULE_TASK_SCENARIO}/${moduleUUID}`, data);
      
    } catch (error) {
      alert("Something went wrong.")
    } finally{
      getModuleTaskList();
    }
  };

  const getModuleTaskList = async () => {
    try {

      let data = await apiClient.get(`${configData.config.UNO_URL}${XR_MODULE_TASK_SCENARIO}/${moduleUUID}`);
    
      setModuleName(data?.name);
      setModuleID(data?.legacyId);
      let tasks = data?.children[0]?.versions[0]?.data?.tasks || [];
      let scenarios = data?.children[0]?.versions[0]?.data?.scenarios || [];  

      setModuleTaskList(tasks);
      setScenarioList(scenarios);
      if (data?.children[0]?.versions[0]?.data?.lock){
        setLocked(true);
      } 
    
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    // dropped outside the list
    if (reorder) {
      const newTasks = Array.from(moduleTaskList);
      const [movedTask] = newTasks.splice(result.source.index, 1);
      newTasks.splice(result.destination.index, 0, movedTask);
      for (var i in newTasks) {
        if (!locked) {
          newTasks[i].id = Number(i) + 1;
        }
      }
      // newTasks.sort((a, b) => a.priority - b.priority);
      setModuleTaskList(newTasks);
      handleSaveAndProceed(newTasks);
    } else {
      if (locked) return alert("Task List is locked for Legacy Modules");
    }
  };

  const handleSaveCsvList = (list) => {

    let taskList = [...moduleTaskList, ...list];
    // taskList.sort((a, b) => a.priority - b.priority);
    if(!locked){
      taskList = taskList.map((item, index) =>(
        {
          ...item,
          id: index + 1
        })
      );
    }

      handleCloseCsvModule();
      setModuleTaskList(taskList);
      handleSaveAndProceed(taskList);
  };

  const handleSaveScenario = async(list) =>{
       try {
        let data = {
          scenarios: list,
        };
        await apiClient.post(`${configData.config.UNO_URL}${XR_MODULE_TASK_SCENARIO}/${moduleUUID}`, data);

        if(list.length === 1 && moduleTaskList.length > 0){
          let updatedTaskList = moduleTaskList.map((e) => ({ ...e , scenario : list[0].id }));
          handleSaveAndProceed(updatedTaskList);
          return
        }
        getModuleTaskList();

       }catch(error){
          console.log(error);
       }
  }

  const findScenario = (scenarioId) =>{
    let find = scenarioList.find(e=> e.id === scenarioId)
    return find ? find.name : null
  }

  const updateTaskListDeleteScenario = (id)=>{
    let taskList = moduleTaskList.map((task) => task.scenario == id ? {...task, scenario:null } : task );
    if(taskList.length){
            setModuleTaskList(taskList);
            handleSaveAndProceed(taskList);
          }
      
  }

  return (
    <>
      <div className="container-fluid" data-aos="fade-left">
        <div className="row align-items-center">
          <div className="col-3">
            <div className="headdetail">
              <span className="pagetitle">Project ID : {moduleID}</span>
              <span className="pagetitle">{moduleName}</span>
            </div>
          </div>
          <div className="col-9 text-end align-items-center uppersection forheadbtn">
          
            <button type='button' className="uloadoanload"  onClick={()=>setShowCsvModule(true)}>
              <UploadIcon />
              <span>Task CSV</span>
            </button>

            <button onClick={handleShowAddTask}> <AddIcon/> Add Task </button>

            <button onClick={handleShowScenario}> <AddIcon/> Scenarios </button>

            {!locked && <button onClick={() => setReorder(!reorder)}> <SortupdownIcon/> Reorder </button> }
            
          </div>
        </div>

        <div className="pt-2">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={moduleTaskList}>
              {(provided) => (
                <table
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="tablewithdrag"
                  style={{ tableLayout: "fixed", width: "100%" }}
                >
                  <thead style={{ width: "100%" }}>
                    <tr>
                      {reorder && <th style={{ width: 0.05 * width }}></th>}
                      <th style={{ width: 0.05 * width }}>Task ID</th>
                      {locked && (
                        <th style={{ width: 0.05 * width }}>Legacy ID</th>
                      )}
                      <th style={{ width: 0.1 * width }}>Title</th>
                      <th style={{ width: 0.2 * width }}>Description</th>
                      <th style={{ width: 0.2 * width }}>Scenario</th>
                      <th
                        style={{ width: 0.05 * width }}
                        className="text-center"
                      >
                        Priority
                      </th>
                      <th
                        style={{ width: 0.05 * width }}
                        className="text-center"
                      >
                        Time
                      </th>
                      <th
                        style={{ width: 0.05 * width }}
                        className="text-center"
                      >
                        Critical
                      </th>
                      <th
                        style={{ width: 0.05 * width }}
                        className="text-center"
                      >
                        <SettingIcon />
                      </th>
                    </tr>
                  </thead>
                  
                  {moduleTaskList.map((data, index) => (
                    <Draggable
                    key={data.id}
                    draggableId={data.id.toString()}
                    index={index}
                    >
                      {(provided) => (
                        <tbody key={data.index + 1}>
                          <tr
                            className=""
                            style={{ border: "none", display: "table" }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {reorder && (
                              <td style={{ minWidth: 0.05 * width }}>
                                <DragListIcon />
                              </td>
                            )}

                            <td style={{ minWidth: 0.05 * width }}>
                              {index + 1}
                            </td>

                            {locked && (
                              <td style={{ minWidth: 0.05 * width }}>
                                {data.id}
                              </td>
                            )}

                            <td className="tabledes" style={{ minWidth: 0.1 * width }} >
                              {data.title}
                            </td>

                            <td className="tabledes" style={{ minWidth: 0.2 * width }} >
                              {data.taskDesc}
                            </td>

                            <td
                              className="tabledes"
                              style={{ minWidth: 0.2 * width }}
                            >
                              {findScenario(data.scenario)}
                            </td>

                            <td
                              className="pri text-center"
                              style={{ minWidth: 0.05 * width }}
                            >
                              {data.priority}
                            </td>

                            <td
                              className="time text-center"
                              style={{ minWidth: 0.05 * width }}
                            >
                              {data.plannedTime}
                            </td>

                            <td style={{ minWidth: 0.05 * width }}>
                              <label className="containercheck">
                                <input
                                  type="checkbox"
                                  checked={data.isCritical}
                                  disabled
                                  onChange={(e) =>
                                    editValue(
                                      data,
                                      "isCritical",
                                      !data.isCritical
                                    )
                                  }
                                />
                                <div className="checkmark"></div>
                              </label>
                            </td>

                            <td
                              className="text-center"
                              style={{ minWidth: 0.05 * width }}
                            >
                              <div className="editicon">
                                <div onClick={() => handleShowEditTask(data)}>
                                  <EditIcon />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Draggable>
                  ))}
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>

      </div>

      <AddTask
        showAddTask={showAddTask}
        handleCloseAddTask={handleCloseAddTask}
        addNewTaskToList={addNewTaskToList}
        scenarioList={scenarioList}
        locked={locked}
        />

      <EditTask
        showEditTask={showEditTask}
        handleCloseEditTask={handleCloseEditTask}
        saveEditTask={saveEditTask}
        scenarioList={scenarioList}
        editValue={editValue}
      />

      <ScenariosList
        showScenario={showScenario}
        handleCloseScenario={handleCloseScenario}
        handleSaveScenario={handleSaveScenario}
        scenarioList={scenarioList}
        updateTaskListDeleteScenario={updateTaskListDeleteScenario}
      />

      <CsvTaskModule
        showCsvModule={showCsvModule}
        handleCloseCsvModule={handleCloseCsvModule}
        handleSaveCsvList={handleSaveCsvList}
        locked={locked}
      />

    </>
  );
};
export default Tasklist;