import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import AOS from "aos";
import Progress from "react-progressbar";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import "aos/dist/aos.css";
import {
  moduleTagCategoryWithTags,
  createNewCustomTags,
  getAllModuleList
} from "../utils";
import { usePageTitle } from '../PageTitleContext'
import { AddIcon, DeleteIcon, GalleryIcon } from "../../Static/icons/iconshere";
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  tableBodyClasses,
} from "@mui/material";
import { event } from "jquery";
import { BtnLoading } from "../../animations/BtnLoading";
import { debounce } from "../../utils/useWindowDimensions";
import apiClient from "../../apiClient";
import { XR_MODULE_DETAIL, LINKED_ASSET_LIST, CONNECT_ASSETS, CATEGORY_ASSETS_LIST, CATEGORY_CATEGORIES_LIST } from "../../apiClient/apiEndpoints";
import LinkModal from "../LinkModal";

const Details = () => {
  const params = useParams();
  const moduleUUID = params.uuid;
  const fileTypes = ["JPG", "PNG", "GIF", "MP4"];
  const configData = useSelector((state) => state.configSlice);
  const tagsData = useSelector((state) => state.tagsSlice);
  const userData = useSelector((state) => state.userSlice);
  const [loading, setLoading] = useState(false);
  const [learningPoints, setLearningPoints] = useState([]);
  const [outcomesPoints, setOutcomesPoints] = useState([]);
  const [mediaGalleryShow, setMediaGalleryShow] = useState(false);
  const [allSavedFileUrl, setAllSavedFileUrl] = useState([]);
  const [uploadedProductFiles, setUploadedProductFiles] = useState([]);
  const [moduleThumbnailUrl, setModuleThumbnailUrl] = useState(null);
  const [thumbnailProgressBar, setThumbnailProgressBar] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [moduleTitleError, setModuleTitleError] = useState(false);
  const [moduleTitle, setModuleTitle] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [courseDuration, setCourseDuration] = useState(0);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedValidate, setSelectedValidate] = useState([]);
  const [selectedJobRoles, setSelectedJobRoles] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [sectorTagOptions, setSectorTagOptions] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [hierarchyList, setHierarchyList] = useState([]);
  const [packagesTagOptions, setPackagesTagOptions] = useState([]);
  const [jobRolesTagsOptions, setJobRolesTagsOptions] = useState([]);
  const [validateTagOptions, setValidateTagOptions] = useState([]);
  const [languageTagsOptions, setLanguageTagsOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [linkedAssetList, setLinkedAssetList] = useState([]);
  const [levelsTagsOptions, setLevelsTagsOptions] = useState([]);
  const [showLinkModel, setShowLinkModel] = useState(false);
  

  const [aboutProjectState, setAboutProjectState] = useState(
    EditorState.createEmpty()
  );
  const { setPageTitle } = usePageTitle();

  useEffect(() => {
    setPageTitle("XR Modules");
  }, []);

  const addLearningPointer = () => {
    setLearningPoints(prevPoints => [...prevPoints, '']);
  };

  const addOutcomesPointer = () => {
    setOutcomesPoints(prevPoints => [...prevPoints, '']);
  };

  const deleteOutcomesPointer = (index) => {

    var newPoints = [...outcomesPoints];
    newPoints.splice(index, 1);
    setOutcomesPoints(newPoints);

  };

  const deleteLearningPointer = (index) => {
    var newPoints = [...learningPoints];
    newPoints.splice(index, 1);
    setLearningPoints(newPoints);

  };


  const handleOutcomesPointChange = (event, index) => {
    var points = [...outcomesPoints];
    points[index] = event.target.value;
    setOutcomesPoints(points)
  }

  const handleLearningPointChange = (event, index) => {
    var points = [...learningPoints];
    points[index] = event.target.value;
    setLearningPoints(points)
  }
  const getModuleDetail = async () => {

    try {
      let _data = moduleTagCategoryWithTags();
      let moduleData = await apiClient.get(`${configData.config.UNO_URL}${XR_MODULE_DETAIL}/${moduleUUID}`);

      setModuleTitle(moduleData?.name);
      setModuleThumbnailUrl(moduleData?.versions[0]?.data?.thumbnail);
      setAllSavedFileUrl(moduleData?.versions[0]?.data?.media || []);
      setLearningPoints(moduleData?.versions[0]?.data?.learningPoints || []);
      setOutcomesPoints(moduleData?.versions[0]?.data?.outcomesPoints || []);
      setShortDescription(moduleData?.versions[0]?.data?.short);
      setCourseDuration(moduleData?.versions[0]?.data?.courseDuration || 0)

      let moduleTags = moduleData?.tags?.map((item) => ({
        value: item.id,
        label: item.name,
        categoryId: item.categoryId,
      }));
      
      setDescription(moduleData?.versions[0]?.data?.description)
      setSelectedTags(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Tags')[0]?.id));
      setSelectedSectors(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Sectors')[0]?.id)[0]);
      setSelectedPackages(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Packages')[0]?.id)[0]);
      setSelectedJobRoles(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Job Roles')[0]?.id));
      setSelectedValidate(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Validated By')[0]?.id)[0]);
      setSelectedLanguage(moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Languages')[0]?.id));
      const selectLevel = moduleTags.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Difficulty Level')[0]?.id)[0]?.value || null;
      if (selectLevel) {
        setSelectedDifficulty(selectLevel);
      }
      const description = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(moduleData?.versions[0].data?.description || "")));
      setAboutProjectState(description);

    } catch (error) {
      console.log(error);
    }

  };

  const getLinkedAssetList = async () =>{
    try {
      let list = await apiClient.get(`${configData.config.UNO_URL}${LINKED_ASSET_LIST}/${moduleUUID}`);

      let linkedResource = list.linkedResources.map(item =>({
              name: item.name,
              uuid: item.uuid,
            }));

      setLinkedAssetList(linkedResource);
      
    } catch (error) {
      console.log(error);
    }
  }

  const handleConnectAsset = async () =>{
    try {

      let _bodyData = {
        ids: linkedAssetList.map(e => e.uuid)
      }

      await apiClient.post(`${configData.config.UNO_URL}${CONNECT_ASSETS}/${moduleUUID}`, _bodyData);
      getLinkedAssetList()
      
    } catch (error) {
      console.log(error);
    }
  }
  
  const getCategoryAssetList = async(id) => {
    try {
      let data = await apiClient.get(`${configData.config.UNO_URL}${CATEGORY_ASSETS_LIST}/${id}`);
      setAssetsList(data);

    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryList = async(id) =>{
    let data = await apiClient.get(`${configData.config.UNO_URL}${CATEGORY_CATEGORIES_LIST}/${id}`);
    setCategoryList(data.children);
    setHierarchyList(data.heirarchy);

  }

  useEffect(() => {

    getCategoryAssetList(configData.config["3dAssetsId"]);
    getCategoryList(configData.config["3dAssetsId"]);

  }, [configData.config["3dAssetsId"]]);

  const handleSelectLinkedResource = async(data) =>{
    let list = [...linkedAssetList];
    const index = list.findIndex(el => el.uuid === data.uuid);
    if (index > -1) {
      list.splice(index, 1);
  } else {
      list.push(data);
  }

  setLinkedAssetList(list);

  }

  const handleCloseLinkModal = async(value) =>{
    if(!value){
      getCategoryAssetList(configData.config["3dAssetsId"]);
      getCategoryList(configData.config["3dAssetsId"]);
    }
    setShowLinkModel(false)
  }

  useEffect(() => {
    setUploadedProductFiles([]);
    setAllSavedFileUrl([]);
    AOS.init();
    AOS.refresh();
  }, []);

  const getAllModuleData = () => {
    let _data = moduleTagCategoryWithTags();
    setSelectedDifficulty(_data?._difficultyLevelTags[1].value || null);
    setSectorTagOptions(_data?._sectorsList);
    setPackagesTagOptions(_data?._packagesList);
    setJobRolesTagsOptions(_data?._jobRolesTags);
    setLanguageTagsOptions(_data?._languagesTags);
    setValidateTagOptions(_data?._ValidatedByLevelTags);
    setLevelsTagsOptions(_data?._difficultyLevelTags);
    setTagsOptions(_data?._Tags);
    getModuleDetail();
    getLinkedAssetList();
  }

  useEffect(() => {
    if (moduleUUID.length > 0 && tagsData?.allTagsList?.name) {
      getAllModuleData();
    }
  }, [moduleUUID, tagsData]);

  const handleHandoverAboutChangeInput = (event) => {
    setAboutProjectState(event);
    setDescription("" + draftToHtml(convertToRaw(event.getCurrentContent())));
  };

  const removeThumbnail = () => {
    setModuleThumbnailUrl(null);
    setFileError(null);
  };

  const uploadThumbnailImage = (file) => {
    setFileError(null);
    setModuleThumbnailUrl(null);
    if (file.size > 2 * 1024 * 1024) {
      setFileError("File Size is more then 2 MB");
    } else {

      setThumbnailProgressBar(true)
      const formData = new FormData();
      formData.append('file', file);

      axios.post(`${configData.config.UNO_URL}/api/resource/upload/thumbnail/${moduleUUID}`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "multipart/form-data"
        }
      }).then((res) => {
        setModuleThumbnailUrl(res?.data?.url)
        setThumbnailProgressBar(false)
      }).catch((err) => {
        setModuleThumbnailUrl(null);
        console.log(err);
      })

    }

  };

  const handleUpdateModuleDetails = async() => {
  try {
    
    setModuleTitleError(false);
    if (moduleTitle.length == 0) {
      setModuleTitleError(true);
      setTimeout(() => { setModuleTitleError(false) }, 2500);
      return false
    };

    if (courseDuration.length == 0) {
      setModuleTitleError(true);
      setTimeout(() => { setModuleTitleError(false) }, 2500);
      return false
    };

    let allTagsList = [];

    if (selectedSectors?.value) {
      allTagsList.push(Number(selectedSectors.value))
    }
    if (selectedPackages?.value) {
      allTagsList.push(Number(selectedPackages.value))
    }

    if (selectedValidate?.value) {
      allTagsList.push(Number(selectedValidate.value))
    }

    selectedTags?.map((e) => {
      return allTagsList.push(Number(e?.value));
    });

    selectedJobRoles?.map((e) => {
      return allTagsList.push(Number(e?.value));
    });

    selectedLanguage?.map((e) => {
      return allTagsList.push(Number(e?.value));
    });

    if (selectedDifficulty) {
      allTagsList.push(Number(selectedDifficulty));
    }

    let data = {
      name: moduleTitle,
      tags: [...allTagsList],
      data: {
        thumbnail: moduleThumbnailUrl,
        media: allSavedFileUrl,
        short: shortDescription,
        description: description,
        courseDuration: courseDuration,
        learningPoints: learningPoints,
        outcomesPoints: outcomesPoints,
      },
    };

    handleConnectAsset();
    await axios.post(`${configData.config.UNO_URL}/api/resource/module/basic/${moduleUUID}`, data, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      }})
     
  } catch (error) {
    console.log(error);
  }finally{
    setLoading(false)
  }

  };

  const handleProductImageChange = (files) => {
    const maxImageSize = 10 * 1024 * 1024;
    const filteredFiles = Array.from(files);

    const imageFiles = [];
    const videoFiles = [];

    filteredFiles.forEach((file) => {
      if (file.type.startsWith("image/") && file.size <= maxImageSize) {
        imageFiles.push(file);
      } else if (file.type.startsWith("video/")) {
        videoFiles.push(file);
      }
    });

    const updatedFiles = [...imageFiles, ...videoFiles].map((file) => ({
      file,
      progress: 0,
    }));

    setUploadedProductFiles([...uploadedProductFiles, ...updatedFiles]);

    updatedFiles.forEach((file) => {
      uploadFile(file.file);
    });

  }

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/media/${moduleUUID}`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "multipart/form-data"
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          updateFileProgress(file, Math.floor(progress));
        }
      });


      updateFileProgress(file, 100, response?.data?.url);


    } catch (error) {
      console.error(error);

    }
  };

  const updateFileProgress = (file, progress, url = null) => {
    setUploadedProductFiles((prevFiles) =>
      prevFiles.map((upload) => {
        if (upload.file === file) {
          return { ...upload, progress, url };
        }
        return upload;
      }));
  };



  const saveProductImagesFile = async () => {
    let allFileArray = [...allSavedFileUrl, ...uploadedProductFiles].map(({ url }) => ({ url }));
    setAllSavedFileUrl(allFileArray)
    setUploadedProductFiles([]);
    GalleryClose();
  }

  const deleteProductImage = (type, url) => {
    if (type === "allSavedFileUrl") {
      const filteredData = allSavedFileUrl.filter(obj => obj.url !== url);
      setAllSavedFileUrl(filteredData)
    } else if (type === "uploadedProductFiles") {
      const filteredData = uploadedProductFiles.filter(obj => obj.url !== url);
      setUploadedProductFiles(filteredData)
    }
  }


  const GalleryClose = () => setMediaGalleryShow(false);
  const GalleryShow = () => setMediaGalleryShow(true);

  const handleChangeLevel = (level) => {
    setSelectedDifficulty(level)
  }

  const debounceHandleSaveReview = debounce(handleUpdateModuleDetails, 1000, setLoading);


  return (
    <>
      <div className="detailspage container-fluid" data-aos="fade-left">

        <div className="mainbox">
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col p-0">
                  {thumbnailProgressBar ?
                    <div className="uploadProgressBar">
                      <div className="progress mt-2" style={{ height: '10px' }}>
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", height: '10px' }}></div>
                      </div>
                      <p>Uploading<span className="dot-animation"></span></p>
                    </div>
                    :
                    <div className="modulepic justify-content-start">
                      <div className="thumbnailImageDiv">
                        {moduleThumbnailUrl?.length > 0 ? (
                          <div>
                            <img
                              id="thumbnailImage"
                              src={moduleThumbnailUrl}
                              alt="Uploaded Thumbnail"
                            />
                            <span className="canceldelete" onClick={removeThumbnail}>
                              X
                            </span>
                          </div>
                        ) : (
                          <div className="fileuploader">
                            <FileUploader
                              handleChange={uploadThumbnailImage}
                              name="thumbnailInput"
                              types={fileTypes}
                              label="drag & drop or choose file to upload"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  }

                </div>

                <div className="col imgupload">
                  <label className="mb-1">Thumbnail Image</label>
                  <p>Recommended Ratio* (16:9)</p>
                  <p className="fileError">{fileError}</p>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col">
                  <div className="modulepic justify-content-start">
                    <button onClick={GalleryShow} className="productpopup">
                      <GalleryIcon />
                      <p>Choose files to upload</p>
                    </button>
                  </div>
                </div>
                <div className="col p-0">
                  <label className="mb-2">Media Gallery</label>
                  {allSavedFileUrl?.length > 0 && <p className="fileSuccess hoverCursor" onClick={GalleryShow} > 0{allSavedFileUrl.length} Files Uploaded</p>}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Course Name *</label>
                <input
                  type="text"
                  value={moduleTitle}
                  onChange={(e) => setModuleTitle(e.target.value)}
                  className="form-control mt-2"
                  id="exampleFormControlInput1"
                  placeholder="Enter course name"
                />

              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Sectors *</label>
                <Select
                  name="sectors"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Sectors"
                  options={sectorTagOptions}
                  value={selectedSectors}
                  onChange={setSelectedSectors}
                  components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Sectors" }) }}
                  filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                />
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Job Roles</label>
                <Select
                  isMulti
                  name="jobRoles"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Job Roles"
                  options={jobRolesTagsOptions}
                  value={selectedJobRoles}
                  onChange={setSelectedJobRoles}
                  components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Job Roles" }) }}
                  filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                />
              </div>
            </div>

            <div className="col-md-4 mt-2">
              <div className="form-group">
                <div>
                  <label htmlFor="exampleFormControlInput1">Package *</label>
                  <Select
                    name="Packages"
                    className="basic-multi-select mt-2"
                    classNamePrefix="select"
                    placeholder="Select Sectors"
                    options={packagesTagOptions}
                    value={selectedPackages}
                    onChange={setSelectedPackages}
                    components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Packages" }) }}
                    filterOption={(option, inputValue) =>
                      option.label.toLowerCase().includes(inputValue.toLowerCase())
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group pt-3">
                <label htmlFor="exampleFormControlShortDesc" className="form-label">
                  Short Description
                </label>
                <input
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  placeholder="Short Description"
                  className="form-control mt-2"
                  id="exampleFormControlShortDesc"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group pt-3">
                <label className="form-label">
                  Level
                </label>
                <FormControl fullWidth className="mb-0 mt-2 pt-0 d-flex align-items-start" >
                  <RadioGroup
                    row
                    aria-labelledby="price-visibility-label"
                    name="questionType"
                    className="gap-3"
                    value={selectedDifficulty}
                    onChange={(e) => handleChangeLevel(e.target.value)}
                  >
                    {levelsTagsOptions.length > 0 && levelsTagsOptions.map((level, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          className="quizlabel"
                          value={level?.value}
                          control={<Radio />}
                          label={level.label}
                        />
                      )
                    })}
                  </RadioGroup>
                </FormControl>

              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group pt-3">
                <label htmlFor="exampleFormControlInput1">Language</label>
                <Select
                  isMulti
                  name="Language"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Language"
                  options={languageTagsOptions}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                  filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">



                <div className="col-md-12">
                  <div className="form-group pt-2 mt-0">
                    <label htmlFor="exampleFormControlInput1">Course Duration:</label>
                    <div className="pointer-list">
                      <input
                        type="number"
                        value={courseDuration}
                        onChange={(e) => setCourseDuration(e.target.value)}
                        placeholder="Enter time in minutes"
                        className="form-control mt-2"
                        id="exampleFormControlShortDesc"
                      />
                    </div>
                  </div>
                </div>


                <div className="col-md-12">
                  <div className="form-group pt-3 mt-1">
                    <label htmlFor="exampleFormControlInput1">What you will learn ?</label>
                    <div className="pointer-list">
                      {learningPoints?.map((point, index) => (
                        <span key={index} className="pointer-here">
                          <input
                            placeholder={`Point number : ${index + 1}`}
                            className="form-control mt-1"
                            id="exampleFormControlShortDesc"
                            value={point}
                            onChange={(event) => handleLearningPointChange(event, index)}
                          />
                          <button className="delete-point" onClick={() => deleteLearningPointer(index)}>
                            <DeleteIcon />
                          </button>
                        </span>
                      ))}
                      <button className="addpoint" onClick={addLearningPointer}>
                        <AddIcon />
                        Add
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group pt-3 mt-1">
                    <label htmlFor="exampleFormControlInput1">Outcomes :</label>
                    <div className="pointer-list">
                      {outcomesPoints?.map((point, index) => (
                        <span key={index} className="pointer-here">
                          <input
                            placeholder={`Point number : ${index + 1}`}
                            className="form-control mt-1"
                            id="exampleFormControlShortDesc"
                            value={point}
                            onChange={(event) => handleOutcomesPointChange(event, index)}
                          />
                          <button className="delete-point" onClick={() => deleteOutcomesPointer(index)}>
                            <DeleteIcon />
                          </button>
                        </span>
                      ))}
                      <button className="addpoint" onClick={addOutcomesPointer}>
                        <AddIcon />
                        Add
                      </button>
                    </div>
                  </div>
                </div>



              </div>
            </div>

            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group pt-2 mt-0">
                    <label htmlFor="exampleFormControlInput1">Validated By</label>
                    <Select
                      // isMulti
                      name="Language"
                      className="basic-multi-select mt-2"
                      classNamePrefix="select"
                      placeholder="Select Language"
                      options={validateTagOptions}
                      value={selectedValidate}
                      onChange={setSelectedValidate}
                      createNewCustomTags
                      components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Validated By" }) }}
                      filterOption={(option, inputValue) =>
                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group pt-2 mt-0">
                    <label htmlFor="exampleFormControlInput1">Tags</label>
                    <Select
                      isMulti
                      name="Tags"
                      className="basic-multi-select mt-2"
                      classNamePrefix="select"
                      placeholder="Select Tags"
                      options={tagsOptions}
                      value={selectedTags}
                      onChange={setSelectedTags}
                      components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Tags" }) }}
                      filterOption={(option, inputValue) =>
                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group pt-3">
                  <label htmlFor="exampleFormControlDescription" className="form-label">
                    Description
                  </label>
                  <div className="modalDescription">
                    <Editor
                      name="project_about"
                      id="exampleFormControlDescription"
                      editorState={aboutProjectState}
                      stripPastedStyles={true}
                      wrapperClassName="demo-wrapper"
                      className="mt-2"
                      editorClassName="demo-editor"
                      onEditorStateChange={handleHandoverAboutChangeInput}
                      toolbar={{
                        options: ["inline", "remove", "history"],
                        inline: {
                          inDropdown: false,
                          options: ["bold", "italic", "underline", "strikethrough"],
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group forthreed pt-3">
                  <label htmlFor="exampleFormControlDescription" className="form-label">
                    3D Models
                  </label>
                  <div className="threeD-model-list">
                    {linkedAssetList.map((item,index)=>(
                        <div className="model-item">
                          <p> {item.name} </p>
                          <button className="delete-point" onClick={()=>handleSelectLinkedResource(item)}>
                            <DeleteIcon />
                          </button>
                        </div>
                    ))}
                  </div>
                  <button className="addpoint" onClick={()=>setShowLinkModel(true)}>
                    <AddIcon />
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 pe-2 mt-4">
        <div className="footerbtn text-end">
          {moduleTitleError && <p className="basicDetailError"> Kindly fill all * field </p>}
        </div>
        <div className="footerbtn text-end">
          <button>Cancel</button>

          <button onClick={debounceHandleSaveReview}>
            {
              loading ?
                <BtnLoading />
                :
                'Save & Submit'
            }
          </button>
        </div>
      </div>

      <Modal size="lg" centered show={mediaGalleryShow} onHide={GalleryClose}>
        <Modal.Header closeButton>Upload Media Gallery (Max file size: 10MB) </Modal.Header>

        <div className="modal-body productimages">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-1">
                <div className="fileuploader popup">
                  <FileUploader
                    name="productFileInputs"
                    multiple
                    types={fileTypes}
                    label="drag & drop or choose file to upload (Max file size: 10MB)"
                    handleChange={handleProductImageChange}
                    className="w-100"
                  />
                </div>
              </div>
            </div>


            <div className="row">

              <div className="productthumbnail">

                {allSavedFileUrl?.map((upload, index) => (
                  <div className="uploadImageProcess" key={index}>
                    <div className="productimgprev">

                      {upload?.url?.split("/").pop().split(".").pop() == "mp4" ?
                        <>
                          <video width="113" height="115" controls>
                            <source src={upload.url || ""} type="video/mp4" />
                            <source src={upload.url || ""} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("allSavedFileUrl", upload.url)}  ></i>}
                        </>
                        :
                        <>
                          <img className="prdimgprw" src={upload.url || ""} alt="image" />
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("allSavedFileUrl", upload.url)}  ></i>}
                        </>
                      }

                    </div>
                  </div>
                ))}

                {uploadedProductFiles.map((upload, index) => (
                  <div className="uploadImageProcess" key={index}>
                    <div className="productimgprev">

                      {upload?.url?.split("/").pop().split(".").pop() == "mp4" ?
                        <>
                          <video width="113" height="115" controls>
                            <source src={upload.url || ""} type="video/mp4" />
                            <source src={upload.url || ""} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("uploadedProductFiles", upload.url)}  ></i>}
                        </>
                        :
                        <>
                          <img className="prdimgprwblr" src={upload.url || ""} alt="image" />
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("uploadedProductFiles", upload.url)}  ></i>}
                        </>
                      }
                    </div>

                    {upload.progress < 100 && <Progress completed={upload.progress} />}
                    {/* <Progress completed={70} /> */}

                  </div>
                ))}

              </div>
            </div>

          </div>
        </div>

        <div className="col-12">
          <div style={{ padding: "1rem" }} className="footerbtn text-end">
            <button type="button" className="btn btn-secondary" onClick={GalleryClose} >Close</button>
            <button type="button" className="btn btn-primary" onClick={saveProductImagesFile} >Save changes</button>
          </div>
        </div>

      </Modal>

      {moduleTitle && <LinkModal moduleTitle={moduleTitle} showLinkModel={showLinkModel} handleCloseLinkModal={(value)=>handleCloseLinkModal(value)} assetsList={assetsList} categoryList={categoryList} hierarchyList={hierarchyList} getCategoryList={getCategoryList} getCategoryAssetList={getCategoryAssetList} linkedAssetList={linkedAssetList} handleSelectLinkedResource={handleSelectLinkedResource} /> }
    </>
  );
};


export default Details;